.react-paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.footer-details {
  text-align: center;
  align-content: center;

  color: #f31529;
}
.css-h393eu .navItemActive {
  background-color: rgb(21 70 19 / 16%) !important;
}
span.footer-details {
  color: #f31529;
}
.sub-header-details {
  text-align: center;
  align-content: center;
  color: #000000;
}
.footer-details-warning {
  text-align: center;
  align-content: center;
  color: #b81d1d;
  padding: 0 0;
}
.react-paginate ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
ul.instruction {
  list-style-type: '\29BF';
  font-size: 15px;
}
.table-bordered {
  padding: 0 0 !important;
}
#total_cost,
.amount-text {
  font-family: Arial, sans-serif;
  font-size: 18px;
  padding: 3px;
  text-align: right;
  font-weight: 800;
}
#candidate {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#candidate td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#candidate tr:nth-child(even) {
  background-color: #f2f2f2;
}

#candidate tr:hover {
  background-color: #ddd;
}

#candidate th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.css-1bwc6vp-MuiTable-root {
  word-wrap: nowrap;
}
.table-striped td {
  margin-left: 2px;
}
.td-header {
  align-items: center;
  text-align: center;
}
.tdclass {
  text-align: center;
}
.css-1u7jyyi-MuiPaper-root-MuiCard-root {
  background-color: #fefffe !important;
  color: rgba(52, 49, 76, 1);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  height: 100%;
  padding: 20px 24px;
}
#change,
#cash_tendered {
  font-family: Arial, sans-serif;
  font-size: 18px;

  text-align: right;
  font-weight: 800;
}
.MuiTableHead-root {
  background-color: #1976d2 !important;
}
.MuiPaper-root {
  overflow-x: auto !important;
}
.MuiTable-root thead tr th {
  padding-left: 11px !important;
  padding-right: 7px !important;
  color: #ffffff !important;
}
.css-wjk3kj-MuiTable-root {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 1500px !important;
  overflow-x: auto;
  white-space: nowrap !important;
}
.tables {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 1500px !important;
  overflow-x: auto;
  white-space: nowrap !important;
}

.MuiInputLabel-root {
  color: rgb(12 11 11) !important;
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
}
td .MuiTableCell-root {
  border-width: 1px;
}
.react-paginate li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #000;
}
.selectedRow {
  background-color: #86cd93;
}
.react-paginate li:hover {
  text-decoration: underline;
}

.react-paginate li.selected {
  font-weight: bold;
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: #fff;
  z-index: 3;
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-link {
  background-color: #fff;
  border: 1px solid #dee2e6;
  color: #0d6efd;
  display: block;
  /* position: relative; */
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
[role='button'] {
  cursor: pointer;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
.error-class {
  color: #ff3d57;
}
a {
  text-decoration: none !important;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.page-item li {
  border-bottom: 1px solid #494949;
  list-style: none;
}

user agent stylesheet li {
  text-align: -webkit-match-parent;
}
.pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
}

#result,
.tblgreen {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  width: 75%;
  border-collapse: collapse;
}
#result td,
#result th,
.tblgreen td,
.tblgreen th {
  font-size: 12px;

  padding: 1px 4px 2px 4px;
}
#result th,
.tblgreen th {
  font-size: 0.9em;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 4px;
  border: solid 1px black;
  color: #ffffff;
}
#result tr.alt td,
.tblgreen tr.alt td {
  color: #000000;
  border: solid 1px black;
}
table.border {
  font-size: 0.45em;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;

  border-bottom-width: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  width: 100%;
  border-spacing: 0px;
}
.scrollbar {
  overflow: auto;
  width: 90%;
  height: 440px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 5pt;
  border: solid 1px black;
  border-width: thin;
  border-color: #a0a0a0;
}
td.civil {
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom-width: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  font-size: 0.45em;
}

td.headers {
  border-top-width: 1px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
  border: solid 0px white;
  border-width: thin;
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 14px;
}

td.border,
th.border {
  border-top-width: 1px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: solid 1px black;
  border-width: thin;
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 14px;
}
td.border1 {
  border-top-width: solid 1px black;
  padding-top: 0px;
  padding-bottom: 0px;
  border: solid 1px black;
  border-width: thin;
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 14px;
}
.header-voting {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.td-success {
  padding-top: 10px;
  margin-left: 101px !important;

  padding-bottom: 0px;
  border: solid 1px rgb(74, 207, 21);
  border-width: thin;
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  background-color: #adffbe !important;
}
.printing {
  visibility: visible;
}
.more-details {
  color: #a00000 !important;
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 16px;
}
.header-voting-2 {
  color: #000000 !important;
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400 !important;
}
.printView {
  visibility: visible;
}
.table-striped-2 {
  width: 50%;
}
.table-dark {
  --bs-table-bg: #1976d2 !important ;
}
.table-success {
  background-color: #63b36e !important;
  color: #fff !important;
  text-align: center;
}
.table-success-2 {
  background-color: #489b53 !important;
  color: #fff !important;
  text-align: center;
}
.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-input {
  width: 150px;
  height: 150px;
  padding: 1px;
  background: #fff;
  box-shadow: -3px -3px 7px rgba(94, 104, 121, 0.377), 3px 3px 7px rgba(94, 104, 121, 0.377);
}
.form-input input {
  display: none;
}
.form-input label {
  display: block;
  width: 45%;
  height: 45px;
  margin-left: 25%;
  line-height: 50px;
  text-align: center;
  background: #1172c2;

  color: #fff;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  text-transform: Uppercase;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.form-input img {
  width: 100%;
  display: none;

  margin-bottom: 30px;
}
.printing .printView {
  /* You can have any CSS here to make the view better on print */
  position: absolute;
  top: 0;
}
.checkbox-stockin {
  display: block;
  position: relative;
  /* padding-left: 54px; */
  /* margin-bottom: 15px; */
  width: 27px;
  height: 17px;
  margin-left: 16px;
  cursor: pointer;
  font-size: 12px;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
